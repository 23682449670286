<template>
    <div class="containter">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="分类名">
                <el-input v-model="form.name" maxlength="10" minlength="2"></el-input>
            </el-form-item>
            <el-form-item label="开启状态">
                <el-switch v-model="form.status" active-value="1" inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="选择分类类型" @change="fetchData(1)">
                  <el-option
                    v-for="item in type_arr"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">立即创建</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import { create } from '@/api/categoryv2'
  export default {
    data() {
      return {
        form: {
          name: '',
          status: '1',
        },
        type_arr:[
          {name:'背景音乐',value:'music'},
          {name:'音效包',value:'sound'},
          {name:'通用',value:'all'},
        ],
      }
    },
    methods: {
      onSubmit() {
        if(!this.form.name){
            this.$message({
                message: '请输入分类名',
                type: 'warning'
            })
            return
        }
        create(this.form).then(response => {
            this.$message({
                message: '创建成功!',
                type: 'success'
            })
            this.$router.push({path:'/sounds_v2/menus'})
        })
      }
    }
  }
</script>
<style scoped>
.containter{
    width: 80%;
    padding: 15px 0;
}
</style>